
import { Goals, Users } from "@/api";
import GoalsChart from "@/components/goals/GoalsChart.vue";
import GoalsDiagram from "@/components/goals/GoalsDiagram.vue";
import Spinner from "@/components/shared/Spinner.vue";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";

const namespace = "auth";

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    GoalsDiagram,
    GoalsChart,
    Spinner
  }
})
export default class GoalsPage extends Vue {
  userGoals: any = [];
  isAdmin = false;
  categories = [];
  seriesData = [];
  users: any = [];
  selectedUser = 0;
  rating: any = {
    rate: 0
  };
  companyTotal: any = {};
  loading = false;
  async updateStatisctics() {
    try {
      this.loading = true;
      this.categories = [];
      this.seriesData = [];
      this.userGoals = [];
      if (this.selectedUser) {
        const goals: any = await Goals.getUserGoalsData(this.selectedUser);
        const goalsByMonths: any = await Goals.getTotalGoalsUser(
          this.selectedUser
        );
        this.userGoals = goals?.data || [];
        this.rating = await Users.getSingleUserRating(this.selectedUser);

        this.userGoals = goals?.data || [];
        const categories = goalsByMonths.data.statisticMonth?.map(
          (month: any) => [moment(month.date).format("DD-MM-YY")]
        );

        this.categories = categories;
        const series = goalsByMonths.data.statisticMonth?.map(
          (month: any) => month[0].progress
        );
        this.seriesData = series;
      } else {
        const goalsTotal: any = await Goals.getGoalsData();
        this.rating.rate = +(await Goals.getRatingCompany()).rate;
        this.users = [
          {
            title: "Company Total",
            key: 0
          },
          ...goalsTotal.data.goals.map((user: any, index: number) => {
            return {
              title: user.userName,
              key: user.userId
            };
          })
        ];
        this.userGoals = [
          {
            progress: goalsTotal.data.totalCompany.totalProgress,
            amount: goalsTotal.data.totalCompany.totalAmount
          }
        ];
        const categories = goalsTotal.data.statisticMonth[0]?.map(
          (month: any) => [moment(month.date).format("DD-MM-YY")]
        );
        this.categories = categories;
        const series = goalsTotal.data.statisticMonth[0]?.map(
          (month: any) => month[0].progress
        );
        this.seriesData = series;
      }
    } catch (errors) {
      await this.$error(errors.errors);
    }
    this.loading = false;
  }
  async mounted() {
    this.loading = true;
    const currentUser: any = this.getUser;
    const isAdmin =
      currentUser && currentUser.permissions
        ? Boolean(
            currentUser.permissions.find(
              (permission: string) => permission === "create_role"
            )
          )
        : false;
    this.isAdmin = isAdmin;
    if (isAdmin) {
      try {
        const goalsTotal: any = await Goals.getGoalsData();
        this.rating.rate = +(await Goals.getRatingCompany()).rate;
        this.rating.rate = +this.rating.rate.toFixed(2);

        const usersList: any = await Users.getCcAgents();
        this.companyTotal = [
          {
            header: "",
            progress: goalsTotal.data.usersMonth.new.progress,
            amount: goalsTotal.data.usersMonth.new.amount,
            sumPrice: goalsTotal.data.usersMonth.new.price
          },
          {
            header: "Existing Customers Target",
            progress: goalsTotal.data.usersMonth.exist.progress,
            amount: goalsTotal.data.usersMonth.exist.amount,
            sumPrice: goalsTotal.data.usersMonth.exist.price
          }
        ];
        this.users = usersList.data.map((user: any) => ({
          title: user.name,
          key: user.id
        }));
        this.users = [
          {
            title: "Company Total",
            key: 0
          },
          ...goalsTotal.data.goals.map((user: any, index: number) => {
            return {
              title: user.userName,
              key: user.userId
            };
          })
        ];
        this.userGoals = [
          {
            progress: goalsTotal.data.totalCompany.totalProgress,
            amount: goalsTotal.data.totalCompany.totalAmount
          }
        ];
        const categories = goalsTotal.data.statisticMonth[0]?.map(
          (month: any) => [moment(month.date).format("DD-MM-YY")]
        );
        this.categories = categories;
        const series = goalsTotal.data.statisticMonth[0]?.map(
          (month: any) => month[0].progress
        );
        this.seriesData = series;
      } catch (errors) {
        await this.$error(errors.errors);
      }
    } else {
      this.selectedUser = currentUser.id;
      try {
        const goals: any = await Goals.getUserGoalsData(currentUser.id);
        this.userGoals = goals && goals.data ? goals.data : [];
      } catch (errors) {
        await this.$error(errors.errors);
      }
    }
    this.loading = false;
  }
  @Getter("getUser", { namespace })
  getUser!: object | null;
}
