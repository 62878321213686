
import { Component, Prop, Vue } from "vue-property-decorator";
import Spinner from "@/components/shared/Spinner.vue";
@Component({
  components: { Spinner }
})
export default class GoalsChart extends Vue {
  @Prop({ required: true, default: () => [] })
  @Prop()
  header?: string;
  @Prop({ required: true, default: () => [] })
  categories?: any;
  @Prop({ required: true, default: () => [] })
  dataSeries?: any;

  series = [
    {
      name: "Targets",
      data: [...this.dataSeries]
    }
  ];
  chartOptions = {
    chart: {
      height: 350,
      type: "bar"
    },

    xaxis: {
      categories: [...this.categories],
      labels: {
        formatter: function(value: any) {
          return value;
        }
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: "top" // top, center, bottom
        }
      }
    }
  };
}
