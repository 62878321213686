
import Spinner from "@/components/shared/Spinner.vue";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({
  components: { Spinner }
})
export default class GoalsDiagram extends Vue {
  @Prop()
  dataChart!: any;
  @Prop()
  header?: string;
  chartOptions = {
    labels: [[`Target: R ${this.amountVal}`, `Sum: R ${this.sumPriceVal}`]],
    plotOptions: {
      radialBar: {
        dataLabels: {
          showOn: "always",
          name: {
            offsetY: -10,
            show: true
          }
        }
      }
    }
  };
  @Prop({ required: false })
  loading?: boolean;

  isAdmin?: boolean;

  $refs!: {
    goalsChart: HTMLFormElement;
  };
  @Watch("dataChart", { deep: true })
  onChartChange() {
    if (!this.loading) {
      this.$refs.goalsChart.updateOptions({
        labels: [[`Target: R ${this.amountVal}`, `Sum: R ${this.sumPriceVal}`]],
        plotOptions: {
          radialBar: {
            dataLabels: {
              showOn: "always",
              name: {
                show: true
              }
            }
          }
        }
      });
    }
  }

  get getHeader() {
    return this.header === "Existing Customers Target"
      ? "Existing Clients"
      : "New Clients";
  }
  get amountVal() {
    return this.dataChart.amount || 0;
  }
  get earnedVal() {
    return this.dataChart.earned || 0;
  }
  get sumPriceVal() {
    return this.dataChart.sumPrice || 0;
  }
  get progressVal() {
    return this.dataChart.progress
      ? this.dataChart?.progress.replace(/,/gi, "")
      : "";
  }
}
